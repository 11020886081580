import { MDBCarouselItem, MDBCarousel, MDBCarouselInner, MDBCol, MDBRow, MDBContainer } from "mdb-react-ui-kit"

const CarouselBanners = () => {
    return(
        <section className="carousel-full-section bg-header" id="Home">
            <MDBContainer>
                <MDBCarousel showIndicators dark fade>
                    <MDBCarouselInner>
                        <MDBCarouselItem className="active">
                            <section className="carousel-item-slide-1 carousel-item-slide">
                                <MDBRow className="justify-content-center">
                                    <MDBCol md="5" lg="5" sm="5" xs="12">
                                        <div className="banner-content-block">
                                            <h1 className="banne-title">
                                            Building Sound Foundations <br/>
                                            <span>to Complete the Circle of <br className="mobile-hide"/> Communication</span>
                                            </h1>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </section>
                        </MDBCarouselItem>
                        <MDBCarouselItem>
                            <section className="carousel-item-slide-2 carousel-item-slide">
                                <MDBRow className="justify-content-center">
                                    <MDBCol md="5" lg="5" sm="5" xs="12">
                                        <div className="banner-content-block">
                                            <h1 className="banne-title">
                                            Building Sound Foundations  <br/>
                                            <span>to Complete the Circle of <br className="mobile-hide"/> Communication</span>
                                            </h1>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </section>
                        </MDBCarouselItem>
                    </MDBCarouselInner>
                </MDBCarousel>
            </MDBContainer>
        </section>
    )
}
export default CarouselBanners;