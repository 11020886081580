import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import reportWebVitals from './reportWebVitals';
import NavbarMenu from './components/NavbarMenu';
import './css/App.scss';
import Footer from './components/Footer';
import { HomePage } from './pages/HomePage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PaymentProcess from './pages/PaymentProcess';
import Support from './pages/Support';
import SupportCenter from './pages/Support';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/Privacy-policy';
export const Page = () => {
  return (
    <>
    <Router>
      <NavbarMenu />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path={process.env.PUBLIC_URL+'/paymentProcess'} component={PaymentProcess} />
            <Route exact path={process.env.PUBLIC_URL+'/paymentProcess/:plan'} component={PaymentProcess} />
            <Route exact path={process.env.PUBLIC_URL+'/check'} component={HomePage} />
            <Route exact path='/support' component={SupportCenter} />
            <Route exact path='/terms-conditions' component={TermsConditions} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          </Switch>
        <Footer />
      </Router>
    </>
  )
}
ReactDOM.render(
  <React.StrictMode>
    <Page />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
