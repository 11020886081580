import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { MDBCol, MDBContainer, MDBRow, MDBBtn } from 'mdb-react-ui-kit';
import profile from '../assets/Kavita_Kaul.png';
import profile2 from '../assets/Sheila Allen.jpg';
import profile3 from '../assets/Patricia-Andrich-1.png';
import profile4 from '../assets/april_christopherson.jpg'
const RegisteredInstructors = () => {
    const responsive = {
        0:{
                items:1,
                nav:true
            },
            600:{
                items:1,
                nav:false
            },
            1000:{
                items:1,
                nav:true,
                loop:false
            }
    }
    return (
        <section className='registeredInstructors'>
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="7" lg="7" sm="7" xs="12" className='self-center'>
                        <h2 className="section-title">Our Registered <br/>Providers</h2>
                        <p className='instructor-text'>Book appointments conveniently with minimal wait time.</p>
                    </MDBCol>
                    <MDBCol md="5" lg="5" sm="5" xs="12">
                        <OwlCarousel className='owl-theme' loop margin={20} nav responsive={responsive} dots={false} navText={[
                            '<span class="arrow prev"><img src="/v3/left Arrow.svg" /></span>',
                            '<span class="arrow next"><img src="/v3/right Arrow.svg" /></span>'
                            ]}>
                            <div class='item'>
                                <div className='registered-instructor-block'>
                                    <MDBRow>
                                        <MDBCol md="5" lg="5" sm="5" xs="12" className='self-center'>
                                            <div className='image-block'>
                                                <img src={profile} alt="Kavita Kaul" title='Kavita Kaul' />
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="7" lg="7" sm="7" xs="12" className='self-center'>
                                            <h4 className='instructor-title'>Kavita Kaul</h4>
                                            <p className='designation'>M.S., Au.D., CCC-SLP/A</p>
                                            <div className="button">
                                                <MDBBtn className="btn-primary">Request an Appointment</MDBBtn>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                                {/* <div className='registered-instructor-block'>
                                    
                                    
                                    {/* <p><span>33 Years Experience Overal</span></p> 

                                    
                                </div>*/}
                            </div>
                            {/* <div class='item'>
                                <div className='registered-instructor-block'>
                                    <MDBRow>
                                        <MDBCol md="5" lg="5" sm="5" xs="12" className='self-center'>
                                            <div className='image-block'>
                                                <img src={profile} alt="Kavita Kaul" title='Kavita Kaul' />
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="7" lg="7" sm="7" xs="12" className='self-center'>
                                            <h4 className='instructor-title'>Kavita Kaul</h4>
                                            <p className='designation'>M.S., Au.D., CCC-SLP/A</p>
                                            <div className="button">
                                                <MDBBtn className="btn-primary">Book an Appointment</MDBBtn>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                                {/* <div className='registered-instructor-block'>
                                    
                                    
                                    {/* <p><span>33 Years Experience Overal</span></p> 

                                    
                                </div>
                            </div>*/}
                            {/* <div class='item'>
                                <div className='registered-instructor-block'>
                                    <div className='image-block'>
                                        <img src={profile2} alt="Sheila Allen" title='Sheila Allen' />
                                    </div>
                                    <h4 className='instructor-title'>Sheila Allen</h4>
                                    <p className='designation'>MA, OT</p>
                                    <p><span>8 Years Experience Overall</span></p>
                                    <div className="button">
                                        <MDBBtn className="btn-primary">Book an Appointment</MDBBtn>
                                    </div>
                                </div>
                            </div>
                            <div class='item'>
                                <div className='registered-instructor-block'>
                                    <div className='image-block'>
                                        <img src={profile3} alt="Patti Andrich" title='Patti Andrich' />
                                    </div>
                                    <h4 className='instructor-title'>Patti Andrich</h4>
                                    <p className='designation'>OTR/L, COVT</p>
                                    <p><span>11 Years Experience Overall</span></p>
                                    <div className="button">
                                        <MDBBtn className="btn-primary">Book an Appointment</MDBBtn>
                                    </div>
                                </div>
                            </div>
                            <div class='item'>
                                <div className='registered-instructor-block'>
                                    <div className='image-block'>
                                        <img src={profile4} alt="April Christopherson" title='April Christopherson' />
                                    </div>
                                    <h4 className='instructor-title'>April Christopherson</h4>
                                    <p className='designation'>OTR/L</p>
                                    <p><span>20 Years Experience Overal</span></p>
                                    <div className="button">
                                        <MDBBtn className="btn-primary">Book an Appointment</MDBBtn>
                                    </div>
                                </div>
                            </div> */}
                        </OwlCarousel>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
    )
}
export default RegisteredInstructors;