import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

const SupportCenter = () => {
    return(
        <>
            <section className="customer-support bg-header">
                <div className="bg-page-breadcrumb">
                    <MDBContainer>
                        <MDBRow>
                            <MDBCol md="12" lg="12" sm="12" xs="12">
                                <h4 className="page-breadcrumb-title">Customer Support</h4>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    </div>
            </section>
            <section className="inside-page-content">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md="12" lg="12" sm="12" xs="12">
                            <div className="page-content-block">
                                <h4 className="page-title">Getting Started</h4>
                                <p>Everything you need to know to get started with HearSay products and features. <br/>
                                Use this guide to learn how to join and actively participate in a HearSay Meet. Once in the meeting, you can share your screen, and
                                chat with other participants if the host has allowed it. If you haven't already, registered with HearSay Meet signup and get started.</p>
                            </div>
                            <div className="page-content-block mt-5">
                                <h4 className="page-title">Account Management</h4>
                                <p>Manage your account settings, users and pay your bill in HearSay Meet comprehensive web portal. These support tutorials and resources help you understand your HearSay invoice, learn more about user and meeting security features, and troubleshoot common account related issues.</p>
                                <div className="inner-page-list-block">
                                    <h6>My HearSay Account</h6>
                                    <ul>
                                        <li>HearSay Profile: Signup/Register with HearSay Meet and login with your provided login credentials</li>
                                        <li>Account Access: Access your account with provided login details and create, manage schedule/reshedule meetings with your students</li>
                                        <li>Account Settings: You can reset your profile name, mobile number, email address.</li>
                                    </ul>
                                </div>
                                <div className="inner-page-list-block">
                                    <h6>Admin Management</h6>
                                    <ul>
                                        <li>Managing Account</li>
                                        <li>Managing Users</li>
                                    </ul>
                                </div>
                                <div className="inner-page-list-block">
                                    <h6>Security Management</h6>
                                    <ul>
                                        <li>Securing your HearSay Account</li>
                                        <li>Securing your HearSay Meetings</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="page-content-block mt-5">
                                <h4 className="page-title">Product Support</h4>
                                <p>Join, collaborate, and connect through Zoom's virtual meeting platform capabilities. Get help with video conferencing and remote meetings from scheduling and joining a meeting to troubleshooting technical issues, like error codes and meeting launch failure.</p>
                            </div>
                            <div className="page-content-block mt-5">
                                <h4 className="page-title">Audio & Video</h4>
                                <p><strong>Audio:</strong> HearSay Meet includes HD quality audio features and settings to optimize your HearSay experience. Use these support articles to join audio using a Windows or MacOS computers, configure speaker and microphone settings, or start a Personal Audio Conference.</p>
                                <p><strong>Video:</strong> You can adjust your video settings before or during a meeting to accommodate your camera and video layout. Learn more about available settings you can enable and use to enhance your virtual video conference experience on the HearSay platform.</p>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                
            </section>
        </>
    )
}
export default SupportCenter;