import { MDBRow,MDBContainer, MDBCol } from "mdb-react-ui-kit";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Screenshot1 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-01.png'
import Screenshot2 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-02.png'
import Screenshot3 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-03.png'
import Screenshot4 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-04.png'
import Screenshot5 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-05.png'
import Screenshot6 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-06.png'
import Screenshot7 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-07.png'
import Screenshot8 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-08.png'
import Screenshot9 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-09.png'
import Screenshot10 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-10.png'
import Screenshot11 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-11.png'
import Screenshot12 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-12.png'
import Screenshot13 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-13.png'
import Screenshot14 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-14.png'
import Screenshot15 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-15.png'
import Screenshot16 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-16.png'
import Screenshot17 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-17.png'
import Screenshot18 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-18.png'
import Screenshot19 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-19.png'
import Screenshot20 from '../assets/Application-ScreenShots/00_HS_All_Screens-DarkTheme-20.png'
import Screenshot21 from '../assets/Application-ScreenShots/02_HS-LiveStreemingScreen-With-AudioControlls-04.png'
export default function HearSayFeaturesExtra() {
    const responsive = {
        0:{
                items:1,
                nav:true
            },
            600:{
                items:2,
                nav:false
            },
            1000:{
                items:3,
                nav:true,
                loop:false
            }
    }
    return (
        <section className="hear-say-extra-features">
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="12" lg="12" sm="12" xs="12">
                        <h4 className="section-title text-center">HearSay App Features</h4>
                        <OwlCarousel className='owl-theme mt-5' loop margin={20} nav responsive={responsive} dots={false} navText={[
                            '<span class="arrow prev"><img src="/left Arrow.svg" /></span>',
                            '<span class="arrow next"><img src="/right Arrow.svg" /></span>'
                            ]}>
                              <div class='item'>
                                <img src={Screenshot1} alt="00_HS_All_Screens-DarkTheme-01" title="00_HS_All_Screens-DarkTheme-01" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot2} alt="00_HS_All_Screens-DarkTheme-02" title="00_HS_All_Screens-DarkTheme-02" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot3} alt="00_HS_All_Screens-DarkTheme-03" title="00_HS_All_Screens-DarkTheme-03" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot4} alt="00_HS_All_Screens-DarkTheme-04" title="00_HS_All_Screens-DarkTheme-04" className="w-100" />
                              </div>
                             <div class='item'>
                                <img src={Screenshot6} alt="00_HS_All_Screens-DarkTheme-06" title="00_HS_All_Screens-DarkTheme-06" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot7} alt="00_HS_All_Screens-DarkTheme-07" title="00_HS_All_Screens-DarkTheme-07" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot8} alt="00_HS_All_Screens-DarkTheme-08" title="00_HS_All_Screens-DarkTheme-08" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot9} alt="00_HS_All_Screens-DarkTheme-09" title="00_HS_All_Screens-DarkTheme-09" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot10} alt="00_HS_All_Screens-DarkTheme-10" title="00_HS_All_Screens-DarkTheme-10" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot11} alt="00_HS_All_Screens-DarkTheme-11" title="00_HS_All_Screens-DarkTheme-11" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot12} alt="00_HS_All_Screens-DarkTheme-12" title="00_HS_All_Screens-DarkTheme-12" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot13} alt="00_HS_All_Screens-DarkTheme-13" title="00_HS_All_Screens-DarkTheme-13" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot14} alt="00_HS_All_Screens-DarkTheme-14" title="00_HS_All_Screens-DarkTheme-14" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot16} alt="00_HS_All_Screens-DarkTheme-16" title="00_HS_All_Screens-DarkTheme-16" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot17} alt="00_HS_All_Screens-DarkTheme-17" title="00_HS_All_Screens-DarkTheme-17" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot18} alt="00_HS_All_Screens-DarkTheme-18" title="00_HS_All_Screens-DarkTheme-18" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot19} alt="00_HS_All_Screens-DarkTheme-19" title="00_HS_All_Screens-DarkTheme-19" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot20} alt="00_HS_All_Screens-DarkTheme-20" title="00_HS_All_Screens-DarkTheme-20" className="w-100" />
                              </div>
                              <div class='item'>
                                <img src={Screenshot21} alt="00_HS_All_Screens-DarkTheme-21" title="00_HS_All_Screens-DarkTheme-21" className="w-100" />
                              </div>
                        </OwlCarousel>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
       </section>
    )
}