import React, { useEffect, useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarLink,
  MDBIcon,
  MDBCollapse
} from 'mdb-react-ui-kit';
import Logo from '../assets/Logo.png';
const NavbarMenu = () => {
    const [showNavSecond, setShowNavSecond] = useState(false);
    const [buyNowActive,setBuyNowActive]=useState(false);
    const [scroll, setScroll] = useState('');
    useEffect(()=>{
        window.addEventListener('scroll', () => {
            let activeClass = 'bg-transparent';
            if(window.scrollY>20){
                activeClass = 'bg-secondary';
            }
            navHighlighter();
            setScroll(activeClass)
            
         });
    })
    let sectionId='';
    const [menuActive,setMenuActive]=useState('Home');
    const sections = document.querySelectorAll("section[id]");
    const navHighlighter = () => {
        // Get current scroll position
        let scrollY = window.pageYOffset;
        
        // Now we loop through sections to get height, top and ID values for each
        sections.forEach(current => {
            const sectionHeight = current.offsetHeight;
        
            // Original:
            // const sectionTop = current.offsetTop - 50;
            //  
            // Alex Turnwall's update:
            // Updated original line (above) to use getBoundingClientRect instead of offsetTop, based on:
            // https://plainjs.com/javascript/styles/get-the-position-of-an-element-relative-to-the-document-24/
            // https://newbedev.com/difference-between-getboundingclientrect-top-and-offsettop
            // This allows the use of sections inside a relative parent, which I'm not using here, but needed for a project
            //
            const sectionTop = (current.getBoundingClientRect().top + window.pageYOffset) - 100;
            sectionId = current.getAttribute("id");
            
            /*
            - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
            - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
            */
            if (
            scrollY > sectionTop &&
            scrollY <= sectionTop + sectionHeight
            ){
            setMenuActive(sectionId)
            } else {
            }
        });
    }
    return(
        <>
        <MDBNavbar expand='lg' light fixed="top" className={scroll} id="top-menu">
        <MDBContainer>
            <MDBNavbarBrand href={`${process.env.PUBLIC_URL}/`}>
                <MDBNavbarLink href={`${process.env.PUBLIC_URL}/`}><img src={Logo} className='img-fluid shadow-4 logo' alt='Logo' title="Logo" /></MDBNavbarLink></MDBNavbarBrand>
            <MDBNavbarToggler
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNavSecond(!showNavSecond)}
            >
            <MDBIcon icon='bars' fas />
            </MDBNavbarToggler>
            <MDBCollapse navbar show={showNavSecond}>
            <MDBNavbarNav className="ml-auto w-auto navbar-nav" id="list-example">
                <MDBNavbarLink aria-current='page' href={`${process.env.PUBLIC_URL}/`} className={menuActive==='Home'?'active':''}>
                Home
                </MDBNavbarLink>
                <MDBNavbarLink href={`${process.env.PUBLIC_URL}/#features`} className={menuActive==='features'?'active':''}>Features</MDBNavbarLink>
                <MDBNavbarLink href={`${process.env.PUBLIC_URL}/#pricing`} className={menuActive==='pricing'?'active':''}>Pricing & Plans</MDBNavbarLink>
                <MDBNavbarLink href={`${process.env.PUBLIC_URL}/#schedulecall`} className={menuActive==='schedulecall'?'active':''}>Schedule a call</MDBNavbarLink>
                <MDBNavbarLink href={`${process.env.PUBLIC_URL}/#pricing`} className={menuActive==='freeTrail'?'active':''}>Free Trail/Buy Now </MDBNavbarLink>
                <MDBNavbarLink href='https://ui.hearsaymeet.com/#/login' target="_blank">Sign In</MDBNavbarLink>
            </MDBNavbarNav>
            </MDBCollapse>
        </MDBContainer>
        </MDBNavbar>
        </>
    )
}
export default NavbarMenu;