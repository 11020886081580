import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import VideoStream from '../assets/feature-icon01-video-Streaming-01.png';
import VideoEncrypted from '../assets/feature-icon02-video-encrypted-01.png';
import ActiveSpeaker from '../assets/feature-icon03-ActiveSpeaker-01.png';
import RaiseHand from '../assets/feature-icon04-RaiseHand-01.png';
import StreamingVideo from '../assets/feature-icon05-StreamingVideo-in-Desktop-And-Lapto-01.png'
import SteamingAudio from '../assets/feature-icon06-Steaming-Audio-only-01.png'
import ScreenSharing from '../assets/feature-icon07-ScreenSharing-01.png'
import VideoRecording from '../assets/video-record.png';
const Features = () => {
    return(
        <section className="features" id="features">
            <MDBContainer>
                <MDBRow className="text-center mb-5">
                    <MDBCol md="12" lg="12" sm="12" xs="12">
                        <h2 className="section-title">HearSay App Highlights</h2>
                        {/* <p>It is a versatile browser based app, accessible from any Windows or MacOS computers.</p>
                        */}
                        <p>A versatile browser based app, accessible from any Windows or MacOS computers.</p>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="justify-content-center mt-3">
                    <MDBCol md="12" lg="12" sm="12" xs="12">
                        <div className="feature-section-block">
                            <div className="icon">
                                <img src={VideoStream} alt="video streaming" title="video streaming" />
                            </div>
                            <div className="text-content">
                                <h4 className="feature-title">Consistent High quality video streaming using globally distributed video bridges and low latency.  {/* The quality of video streaming doesn't get affected wherever you are. */}</h4>
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol md="12" lg="12" sm="12" xs="12" className="mt-3">
                        <div className="feature-section-block">
                            <div className="icon">
                                <img src={VideoEncrypted} alt="video streaming" title="video streaming" />
                            </div>
                            <div className="text-content">
                                <h4 className="feature-title">End-to-end secure encryption conforming to Jitsi Meets.{/* to make sure all traffic is encrypted and safe even during transit. */}</h4>
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol md="12" lg="12" sm="12" xs="12" className="mt-3">
                        <div className="feature-section-block">
                            <div className="icon">
                                <img src={ActiveSpeaker} alt="video streaming" title="video streaming" />
                            </div>
                            <div className="text-content">
                                <h4 className="feature-title">{/* Active speaker auto-view feature yet interactive. */} Interactive meeting features and active speaker auto view.</h4>
                            </div>
                        </div>
                    </MDBCol>
                    {/* <MDBCol md="12" lg="12" sm="12" xs="12" className="mt-3">
                        <div className="feature-section-block">
                            <div className="icon">
                                <img src={RaiseHand} alt="video streaming" title="video streaming" />
                            </div>
                            <div className="text-content">
                                <h4 className="feature-title">Raise/Lower your hand for attention</h4>
                            </div>
                        </div>
    </MDBCol> */}
                    <MDBCol md="12" lg="12" sm="12" xs="12" className="mt-3">
                        <div className="feature-section-block">
                            <div className="icon">
                                <img src={StreamingVideo} alt="video streaming" title="video streaming" />
                            </div>
                            <div className="text-content">
                                <h4 className="feature-title">{/* Stream videos from any other source using your desktop/laptop. */} Stream videos from other web sources.</h4>
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol md="12" lg="12" sm="12" xs="12" className="mt-3">
                        <div className="feature-section-block">
                            <div className="icon">
                                <img src={SteamingAudio} alt="video streaming" title="video streaming" />
                            </div>
                            <div className="text-content">
                                <h4 className="feature-title">{/* You can also meet in audio only option for low bandwidth clients */}Audio only option available in the event of low bandwidth internet connection.</h4>
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol md="12" lg="12" sm="12" xs="12" className="mt-3">
                        <div className="feature-section-block">
                            <div className="icon">
                                <img src={ScreenSharing} alt="video streaming" title="video streaming" />
                            </div>
                            <div className="text-content">
                                <h4 className="feature-title">Screen sharing option available.</h4>
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol md="12" lg="12" sm="12" xs="12" className="mt-3">
                        <div className="feature-section-block">
                            <div className="icon">
                                <img src={VideoRecording} alt="video streaming" title="video streaming" />
                            </div>
                            <div className="text-content">
                                <h4 className="feature-title">Sessions can be recorded and downloaded.</h4>
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
    )
}
export default Features;