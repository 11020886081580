import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit"
import HearnSayImage from '../assets/HearSay_Laptop.png'
const WhatHearnSay = () => {
    return(
        <section className="what-hear-say" id="Home">
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="7" lg="7" sm="7" xs="12">
                        <div className="what-hear-content">
                            <h2 className="hear-title">What is HEARSAY</h2>
                            {/* <p>Hearsay is a versatile and reliable platform for Audiologists and Speech-Language Pathologists for remote service delivery of services for those who  may not have access to trained professionals in their communities.  It is the best alternative to an in-person session. The provider and patient will enjoy access to technology similar to that available in a clinic (including salient audiometric functions such as sound calibration, and stereo; mono; and dichotic presentation of therapy material via headphones or speakers. HearSay platform has been developed based on clinical experience.</p>
                            */}
                            <p>HearSay is a versatile and reliable web based video streaming app designed for Audiologists and Speech-Language Pathologists to deliver services remotely. The provider and patient will enjoy access to technology similar to that available in a clinic, including important audiometric functions such as sound calibration, and audio presentation in stereo; mono; or dichotic modalities via headphones or speakers. Lastly, it has been developed based on needs experienced by the clinician while using other commercially available apps.</p>
                        </div>
                        <section className="what-hear-section-block mt-7">
                            <MDBRow>
                                <MDBCol md="6" lg="6" sm="6" xs="12">
                                    <h3 className="what-hear-instruction-title">For Providers</h3>
                                    {/* <p>Audiologists and Speech-Language Pathologists will enjoy access to unique versatile audio and video features in a secure way to facilitate the most standardized evaluation and therapy session possible (calibration of sound, download personal audio tracks to your account, home practice drills access, convenient scheduling, etc.)</p>
                                    */}
                                    <p>Audiologists and Speech-Language Pathologists will enjoy access to unique and versatile audio and video features in a secure way. It is designed to provide the best reliable and valid sessions using available features (calibration of sound, download personal audio tracks to your account, home practice drills access, convenient scheduling, etc.)</p>
                                </MDBCol>
                                <MDBCol md="6" lg="6" sm="6" xs="12" className="mobile-col-sep">
                                    <h3 className="what-hear-instruction-title">For Clients</h3>
                                    {/* <p>Patients will receive quality in-person sessions via a unique audio-video streaming platform, as well as have easy and efficient access to recommended home practice drills on the app.</p>
                                   */}
                                   <p>Patients will receive quality in-person sessions via a unique audio-video streaming platform. The app provides effective and efficient access to recommended home practice programs. </p>
                                </MDBCol>
                            </MDBRow>
                        </section>
                    </MDBCol>
                    <MDBCol md="5" lg="5" xs="12" sm="5" className="mobile-col-sep">
                        <div className="image">
                            <img src={HearnSayImage} className="w-100" alt='What is HEARSAY App' title="What is HEARSAY App" />
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
    )
}
export default WhatHearnSay;