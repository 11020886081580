import { MDBCol, MDBRow, MDBContainer,MDBValidation,MDBBtn,MDBCheckbox} from "mdb-react-ui-kit"
import moment from "moment";
import { useEffect, useState } from "react";
import {withRouter} from 'react-router-dom'
const PaymentProcess = (props) => {
    const [planUpgrade, setplanUpgrade] = useState({
        name: '',
        clinicName: '',
        email: '',
        mobileNumber: ''
      });
      const [planData,setPlanData]=useState({
          planName: '',
          price: ''
      })
      const [errors,setErrors]=useState()
      const onChange = (e) => {
        setplanUpgrade({ ...planUpgrade, [e.target.name]: e.target.value });
      };
    const currentData = moment().format('MMM Do YYYY')
    const [validated, setValidated] = useState(false);
    useEffect(()=>{
        if(props.match.params) {
            const plan=props.match.params.plan;
            if(plan==='community-monthly') {
                setPlanData({
                    planName: 'Community Monthly plan',
                    price: '$ 10.00'
                })
            }
            else if(plan==='community-quaterly') {
                setPlanData({
                    planName: 'Community Quaterly Plan',
                    price: '$ 30.00'
                })
            }
            else if(plan==='community-yearly') {
                setPlanData({
                    planName: 'Community Yearly Plan',
                    price: '$ 120.00'
                })
            }
        }
    }, [props.match.params])
    return(
        <section className="payment-process bg-header">
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="8" lg="8" sm="8" xs="12" className="payment-details-block">
                        <div className="title-head">
                            <h4 className="hear-title">Payment Details</h4>
                        </div>
                        <div className="payment-process-content">
                            <h6>You are selected “{planData.planName}”</h6>
                            <MDBValidation className='row g-3' noValidate validated={validated}>
                                <MDBCol md="6" lg="6" sm="6" xs="12">
                                    <div className="form-group">
                                        <label>Given Name <sup>*</sup></label>
                                        <input type="text" placeholder="" className="form-control" value={planUpgrade.name} name="name" onChange={onChange} />
                                    </div>
                                </MDBCol>
                                <MDBCol md="6" lg="6" sm="6" xs="12">
                                    <div className="form-group">
                                        <label>Family name</label>
                                        <input type="text" placeholder="" className="form-control" value={planUpgrade.name} name="name" onChange={onChange} />
                                    </div>
                                </MDBCol>
                                <MDBCol md="6" lg="6" sm="6" xs="12">
                                    <div className="form-group">
                                        <label>Card Number <sup>*</sup></label>
                                        <input type="text" placeholder="" className="form-control" value={planUpgrade.name} name="name" onChange={onChange} />
                                    </div>
                                </MDBCol>
                                <MDBCol md="3" lg="3" sm="3" xs="12">
                                    <div className="form-group">
                                        <label>Expiry Date <sup>*</sup></label>
                                        <input type="text" placeholder="" className="form-control" value={planUpgrade.name} name="name" onChange={onChange} />
                                    </div>
                                </MDBCol>
                                <MDBCol md="3" lg="3" sm="3" xs="12">
                                    <div className="form-group">
                                        <label>CVV <sup>*</sup></label>
                                        <input type="text" placeholder="" className="form-control" value={planUpgrade.name} name="name" onChange={onChange} />
                                    </div>
                                </MDBCol>
                                <MDBCol md="9" lg="9" sm="9" xs="12">
                                    <div className="form-group">
                                        <label>Country <sup>*</sup></label>
                                        <input type="text" placeholder="" className="form-control" value={planUpgrade.name} name="name" onChange={onChange} />
                                    </div>
                                </MDBCol>
                                <MDBCol md="3" lg="3" sm="3" xs="12">
                                    <div className="form-group">
                                        <label>Postal/ ZIP <sup>*</sup></label>
                                        <input type="text" placeholder="" className="form-control" value={planUpgrade.name} name="name" onChange={onChange} />
                                    </div>
                                </MDBCol>
                                <MDBCheckbox label='Automatic billing' id='invalidCheck' required />
                                <div className='col-12 mt-3 text-center'>
                                    <MDBBtn type='submit' className='btn-secondary'>Add Payment Details / Verify Details</MDBBtn>
                                </div>
                            </MDBValidation>
                            <MDBRow className="mt-3">
                                <MDBCol md="9" lg="9" sm="9" xs="12">
                                    <MDBCheckbox label='I agree to the subscription and cancellation terms & terms of use' id='invalidCheck' required />
                                    <MDBCheckbox label='I agree to the terms and conditions of use' id='invalidCheck' required />
                                </MDBCol>
                                <MDBCol md="3" lg="3" sm="3" xs="12">
                                    <div className="buttons">
                                        <MDBBtn className="btn-primary btn-start-trial">Start Free Trail</MDBBtn>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBCol>
                    <MDBCol md="4" lg="4" sm="4" xs="12" className="bg-billing-summary">
                        <div className="title-head">
                           <h3 class="what-hear-instruction-title">Billing Summary</h3>
                        </div>
                        <div className="plan-description">
                            <h6>Recurring billing starts only at after your 7 days free trail ends</h6>
                        </div>
                        <table style={{width:'100%'}}>
                            <tbody>
                                <tr>
                                    <td>
                                       <div>Billing date</div>
                                    </td>
                                    <td align="right">
                                       <div><strong>{currentData}</strong></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                       <div>Plan</div>
                                    </td>
                                    <td align="right">
                                       <div><strong>Community</strong></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                       <div>Amount</div>
                                    </td>
                                    <td align="right">
                                       <div><strong>{planData.price}</strong></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{width:'100%'}}>
                            <tbody>
                                <tr>
                                    <td>
                                       <div>Sub Total</div>
                                    </td>
                                    <td align="right">
                                       <div><strong>{planData.price}</strong></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                       <div>Discount</div>
                                    </td>
                                    <td align="right">
                                       <div><strong>{planData.price}</strong></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                       <div>Taxes & Fee</div>
                                    </td>
                                    <td align="right">
                                       <div><strong>{planData.price}</strong></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="title">Total: <strong>{planData.price}</strong></div>
                        <div className='col-12 mt-3 text-center'>
                            <MDBBtn type='submit' className='btn-secondary'>Make a Payment / Cancel Payment</MDBBtn>
                            <p className="mt-3">Cancel before X month to get full refund and avoid free</p>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
    )
}
export default withRouter(PaymentProcess)