import CarouselBanners from '../components/CarouselBanner';
import WhatHearnSay from '../components/WhatHearnSay';
import Features from '../components/Features';
import PricingPlan from '../components/PricingPlan';
import ScheduleDemo from '../components/ScheduleDemo';
import RegisteredInstructors from '../components/RegisteredInstructors';
import HearSayFeaturesExtra from '../components/ExtraFeatures';
export const HomePage = () => {
    return(
        <>
        <CarouselBanners />
        <WhatHearnSay />
        <RegisteredInstructors />
        <Features />
        <HearSayFeaturesExtra />
        <PricingPlan />
        <ScheduleDemo />
    </>
    )
}